<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">제품소개 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 목록 -->
            <v-radio-group v-model="selected" hide-details>
                <v-data-table
                    hide-default-header
                    :items="list"
                    :footer-props="{
                        'items-per-page-options': [8, 20, 100, -1],
                        'itemsPerPageText': '페이지당 제품소개 수'
                    }"
                    no-data-text=""
                >
                    <template v-slot:header>
                        <tr>
                            <th style="width:52px;"></th>
                            <th style="width:240px">페이지</th>
                            <th style="width:180px">기기</th>
                            <th style="width:200px">언어</th>
                            <th style="width:100%">내용</th>
                            <th style="width:240px">마지막 수정일자</th>
                        </tr>
                    </template>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                <v-radio
                                    class="ml-1"
                                    hide-details
                                    dense
                                    :value="item"
                                    @change="selectedItem = item"
                                ></v-radio>
                            </td>
                            <td class="text-truncate">
                                <span v-if="item.page == 'light'">광투과식 매연측정기</span>
                                <span v-if="item.page == 'gas'">가스 분석기</span>
                            </td>
                            <td class="text-truncate">
                                {{item.device == 'pc'? 'PC':'MOBILE'}}
                            </td>
                            <td class="text-truncate">
                                {{item.language == 'kr'? '한국어':'ENGLISH'}}
                            </td>
                            <td class="px-4">
                                <v-sheet
                                    height="40"
                                    style="overflow:hidden;"
                                    color="transparent"
                                >
                                    {{removeHTML(item.content)}}
                                </v-sheet>
                            </td>
                            <td>
                                {{new Date(item.created).toLocaleString()}}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    class="px-6 ml-3"
                    color="orange"
                    large
                    dark
                    depressed
                    @click="updateItem()"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    수정하기
                </v-btn>
            </v-card>
        </v-sheet>
    </div>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"

export default {
    components: {
        TipTapWriter
    },

    data: () => ({
        list: [],

        selected: false,
        selectedItem: {},

        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true,
        }
    }),

    mounted(){
        // 목록
        this.load()
    },

    methods: {
        // 목록
        load(){
            this.$http.post('/api/admin/product/select')
            .then((res) => {
                if(res.data.length){
                    this.list = res.data
                }
            })
        },

        // HTML 제거
        removeHTML(string){
            return string.replace(/<[^>]*>?/g, '')
        },

        // 수정하기
        updateItem(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택한 제품소개가 없습니다")
            }else{
                this.$router.push("/admin/product/update?page="+this.selectedItem.page+"&device="+this.selectedItem.device+"&language="+this.selectedItem.language)
            }
        }
    }
}
</script>
<style scoped>
/* Table (수정하기, 추가하기) */
.customTable{
    border:1px solid #efefef;
    border-collapse: collapse;
}

.customTable tr td:first-child{
    text-align: center;
    font-weight: bold;
    background:#f2f2f2;
    height:50px;
    width:140px;
    padding:10px;
    border:1px solid #dedede;
}

.customTable tr td{
    height:50px;
    width:700px;
    padding:10px;
    border:1px solid #dedede;
}

.my-clipper {
    width:100%;
}

.placeholder {
    width:100%;
    height:220px;
}

.updatetable{
    border:1px solid #efefef;
    border-collapse: collapse;
}

.updatetable tr td{
    height:50px;
    padding:10px;
    border:1px solid #dedede;
}

.updatetable tr th{
    background:#f2f2f2;
    text-align: left;
    padding:10px;
    border:1px solid #dedede;
}
</style>