<template>
    <v-card
        class="mx-auto"
        width="256"
        tile
        color="#313942"
    >
        <v-img
            width="120"
            class="mx-auto mt-10 mb-6"
            src="@/assets/logo/white.svg"
        ></v-img>
        <p class="text-center white--text mb-2">
            관리자 페이지
        </p>
        <v-divider color="grey" class="mx-16"></v-divider>
        
        <div class="d-flex justify-center my-6">
            <v-btn
                v-if="$store.state.ctng_admin.is_logined"
                class="rounded-lg"
                color="grey lighten-1"
                outlined
                to="/admin/auth/logout"
            >
                관리자 로그아웃
            </v-btn>
        </div>

        <!-- 메뉴 그룹 -->
        <v-list
            v-if="$store.state.ctng_admin.is_logined"
            nav
            dense
            color="#313942"
        >
            <v-list-item-group
                v-model="selected"
            >
                <!-- 메뉴 -->
                <v-list-item
                    v-for="(item, index) in menu" :key="index"
                    :to="item.link"
                    class="py-1"
                >
                    <!-- 아이콘 -->
                    <v-list-item-icon>
                        <v-icon color="white">{{item.icon}}</v-icon>
                    </v-list-item-icon>

                    <!-- 제목 -->
                    <v-list-item-content>
                        <v-list-item-title class="white--text">{{item.text}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-card>
</template>
<script>
export default {
    data: () => ({
        menu: [
            { 
                text: "사업소개 관리",
                icon: "mdi-domain",
                link: "/admin/business/list"
            },
            { 
                text: "제품소개 관리",
                icon: "mdi-package-variant-closed",
                link: "/admin/product/list"
            },
            { 
                text: "Q&A 관리",
                icon: "mdi-help-circle-outline",
                link: "/admin/qna/list"
            },
            { 
                text: "공지사항 관리",
                icon: "mdi-post-outline",
                link: "/admin/notice/list"
            },
            { 
                text: "관리자 정보수정",
                icon: "mdi-shield-edit-outline",
                link: "/admin/update"
            },
        ],

        selected: ""
    })
}
</script>