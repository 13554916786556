<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">사업소개 관리 <v-icon class="mb-3px">mdi-chevron-right</v-icon> 수정하기</font>
        </v-sheet>

        <!-- 본문 -->
        <div
            class="d-flex"
        >
            <v-sheet
                width="1000"
                class="pa-2 ma-2"
                outlined
            >
                <div class="d-flex">
                    <p class="mx-1 my-2">
                        <span class="pa-1 text-h6" v-if="$route.query.page == 'light'">광투과식 매연측정기</span>
                        <span class="pa-1 text-h6" v-if="$route.query.page == 'gas'">가스 분석기</span>

                        <span class="pa-1 text-h6 primary--text text--lighten-2">({{$route.query.device == 'pc'? 'PC':'Mobile'}})</span>
                        <span class="pa-1 text-h6 primary--text text--lighten-2">({{$route.query.language == 'kr'? '한국어':'ENGLISH'}})</span>
                    </p>
                </div>

                <!-- 본문 -->
                <TipTapWriter
                    v-if="options.content"
                    class="tiptap_style mt-2"
                    :options="options"
                />

                <!-- 버튼 -->
                <v-sheet class="d-flex justify-center pa-1 mt-2">
                    <v-btn
                        class="mx-2 px-10 rounded-10"
                        color="#ADAFCA"
                        dark
                        large
                        depressed
                        @click="cancel()"
                    >
                        취소하기
                    </v-btn>
                    <v-btn
                        class="mx-2 px-10 rounded-10"
                        color="primary"
                        dark
                        large
                        depressed
                        @click="submit()"
                    >
                        등록하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </div>
    </div>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"

export default {
    components: {
        TipTapWriter
    },

    data: () => ({
        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true
        },
    }),

    mounted(){
        // 게시글 정보 불러오기
        this.$http.post("/api/admin/product/select/specific", {
            params: {
                page: this.$route.query.page,
                device: this.$route.query.device,
                language: this.$route.query.language
            }
        }).then((res) => {
            this.options.content = res.data[0].content
        })
    },

    methods: {
        // 취소하기
        cancel(){
            if(confirm("지금 작성하고 계신 글이 저장되지 않습니다.\n페이지를 이동하시겠습니까?")){
                this.$router.go(-1)
            }
        },

        // 등록하기
        async submit(){
            if(!this.options.content.length)
            {
                alert("내용을 입력해주세요")
            }
            else
            {
                // DB 저장
                this.$http.post('/api/admin/product/update', {
                    params: {
                        content: this.options.content,
                        page: this.$route.query.page,
                        device: this.$route.query.device,
                        language: this.$route.query.language
                    }
                }).then(res => {
                    this.$router.push("/admin/product/list")
                })
            }
        }
    }
}
</script>
<style scoped>
.table_style{
    width:100%;
    background:white;
    border:1px solid #ddd;
    border-collapse: collapse;
}

.table_style tr td{
    border:1px solid #ddd;
    padding:4px;
    text-align: center;
}

.filled{
    background:#ddd;
    color:#999;
    font-weight:300;
}

.reservation{
    background:#bba8ca;
    color:#84679a;
    font-weight:300;
}
</style>

<style scoped>
.my-clipper {
    width:100%;
}

.placeholder {
    width:100%;
    height:150px;
}
</style>